import { Box, IconButton, styled as MUIStyled } from '@mui/material';
import styled from 'styled-components';

export const CloseButton = styled(IconButton)`
    background-color: ${(props) => props.theme.palette.common.white};
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    margin: 0;
    height: 44px;
    width: 44px;

    > svg {
        height: 44px;
        width: 44px;
    }
`;

export const CreditsContainer = styled.div`
    border-radius: 8px;
    padding: 4px 8px;
    background-color: ${(props) => props.theme.palette.grey[50]};
`;

export const ButtonContainer = styled.div`
    width: 100%;

    > div {
        display: flex;
        justify-content: flex-end;
    }

    p {
        color: ${(props) => props.theme.palette.error.main};
        font-size: 14px;
        margin: 12px 0;
    }

    button {
        :first-of-type {
            margin-right: 10px;
        }

        :last-of-type {
            margin-left: 10px;
        }
    }
`;

export const RequestContainer = styled.div`
    flex: 3;
    position: relative;

    &.highlight-class {
        #request-close-button,
        ${ButtonContainer}, a {
            pointer-events: none !important;
        }
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${(props) => !props.isMobile && `calc(100vh - 64px)`};
    /* overflow: auto; */
    height: 100%;
    padding: ${(props) => (props.isMobile ? '2em 1em 5px 1em' : '2em 3em 5px 3em')};
`;

export const CreditsBanner = styled.div`
    display: inline-flex;
    border-radius: 8px;
    padding: 8px 12px;
    align-items: center;
    margin-bottom: 10px;
    align-self: flex-start;
    width: 100%;

    p {
        margin-bottom: 0;
    }

    svg {
        height: 18px;
        margin-right: 10px;

        g {
            stroke: grey;
        }

        text {
            stroke: black;
        }
    }
`;

export const FormTitle = styled.h2`
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 24px;

    > svg {
        vertical-align: sub;
        margin-right: 10px;
    }
`;

export const FormSubtitle = styled.i`
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    width: 16rem;
    top: 21%;
    display: block;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;

export const FormBody = MUIStyled(Box)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 5px;
    padding-top: 1.5em;
`;

export const FormRow = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
`;

export const FormFooter = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.palette.common.white};
    justify-content: flex-end;
    z-index: 100;
    padding: 10px 0;
`;

export const Container = MUIStyled(Box)`
    background-color: ${(props) => props.theme.palette.common.white};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 650px;

    ${({ theme }) =>
        theme.breakpoints.up('md') &&
        `
        flex-direction: row;
    `}
`;
