import { combineReducers } from '@reduxjs/toolkit';
import { enablePatches } from 'immer';
import { LOGGED_OUT } from '../constants/action-types';
import inbound from '../reducers/inboundReducer.js';
import locales from '../reducers/localeReducer.js';
import login from '../reducers/loginReducer.js';
import questionStore from '../reducers/questionReducer.js';
import referenceRequest from '../reducers/referenceRequestReducer.js';
import referencingDashboard from '../reducers/referencingDashboardReducer.js';
import register from '../reducers/registerReducer.js';
import settings from '../reducers/settingsReducer.js';
import site from '../reducers/siteReducer.js';
import subscription from '../reducers/subscriptionReducer.js';
import talentPool from '../reducers/talentPoolReducer.js';
import topUp from '../reducers/topUpReducer.js';
import user from '../reducers/userReducer.js';
import usersAndTeams from '../reducers/usersAndTeamsReducer.js';
import vacancyPool from '../reducers/vacancyPoolReducer.js';
import { initialState } from '../store/initial-state';
enablePatches();

import candidates from '../reducers/candidateReducer';

import candidateOnboardingSlice from '../pages/Forms/Candidate/Slices/candidateSlice.js';

const appReducer = combineReducers({
    candidates,
    inbound,
    referencingDashboard,
    locales,
    login,
    questionStore,
    referenceRequest,
    register,
    settings,
    site,
    subscription,
    talentPool,
    topUp,
    user,
    usersAndTeams,
    vacancyPool,
    forms: combineReducers({
        candidate: candidateOnboardingSlice,
    }),
});

const rootReducer = (state, action) => {
    if (action.type === LOGGED_OUT) {
        state = initialState;
    }

    return appReducer(state, action);
};

export default rootReducer;
