import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.GET_QUESTION_PROFILES:
                draft.isFetchingProfiles = true;
                draft.newProfileSaved = false;

                break;

            case actions.RECEIVED_QUESTION_PROFILES:
                draft.isFetchingProfiles = false;
                draft.profiles = payload.profiles;

                break;

            case actions.ERROR_QUESTION_PROFILES:
                draft.isFetchingProfiles = false;
                draft.profilesErrorMessage = payload.profilesErrorMessage;

                break;

            case actions.GET_QUESTION_PROFILE:
                draft.isFetchingProfile = true;

                break;

            case actions.RECEIVED_QUESTION_PROFILE:
                draft.isFetchingProfile = false;
                draft.currentProfile = {
                    ...draft.currentProfile,
                    ...payload.currentProfile,
                };

                break;

            case actions.ERROR_QUESTION_PROFILE:
                draft.isFetchingProfile = false;
                draft.profileErrorMessage = payload.profileErrorMessage;

                break;

            case actions.ADD_NEW_PROFILE:
                draft.savingNewProfile = true;
                draft.newProfileSaved = false;
                draft.profileSavedMessage = null;
                draft.profileErrorMessage = null;

                break;

            case actions.NEW_PROFILE_SAVED:
                draft.profileSavedMessage = payload.profileSavedMessage;
                draft.profileErrorMessage = null;
                draft.savingNewProfile = false;
                draft.newProfileSaved = true;

                break;

            case actions.ERROR_SAVING_PROFILE:
                draft.profileErrorMessage = payload.profileErrorMessage;
                draft.savingNewProfile = false;
                draft.newProfileSaved = false;

                break;

            case actions.SET_CREATE_PROFILE:
                draft.isCreatingProfile = payload.isCreatingProfile;
                draft.profileErrorMessage = null;
                draft.profileSavedMessage = null;

                break;

            case actions.DELETE_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    questionId: payload.questionId,
                    isDeletingQuestion: true,
                    deletedQuestion: false,
                    deleteQuestionSuccessMessage: null,
                    deleteQuestionErrorMessage: null,
                };

                break;

            case actions.DELETED_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    deleteQuestionSuccessMessage: payload.deleteQuestionSuccessMessage,
                    isDeletingQuestion: false,
                    deletedQuestion: true,
                };

                break;

            case actions.ERROR_DELETING_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    deleteQuestionErrorMessage: payload.deleteQuestionErrorMessage,
                    isDeletingQuestion: false,
                    deletedQuestion: false,
                };

                break;

            case actions.SET_CREATE_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isCreatingQuestion: payload.isCreatingQuestion,
                    isSavingQuestion: false,
                    createdQuestion: false,
                    createdQuestionErrors: [],
                    createdQuestionSuccessMessage: '',
                };

                break;

            case actions.ADD_NEW_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isSavingQuestion: true,
                    createdQuestion: false,
                    createdQuestionErrors: [],
                    createdQuestionSuccessMessage: '',
                };

                break;

            case actions.CREATED_NEW_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    createdQuestionSuccessMessage: payload.createdQuestionSuccessMessage,
                    isSavingQuestion: false,
                    createdQuestion: true,
                };

                break;

            case actions.ERROR_CREATING_NEW_QUESTION:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    createdQuestionErrors: payload.createdQuestionErrors,
                    isSavingQuestion: false,
                    createdQuestion: false,
                };

                break;

            case actions.DELETE_PROFILE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isDeletingProfile: true,
                    profileDeletedMessage: null,
                    errorDeletingProfileMessage: null,
                    profileDeleted: false,
                };

                break;

            case actions.PROFILE_DELETED:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isDeletingProfile: false,
                    profileDeletedMessage: payload.profileDeletedMessage,
                    profileDeleted: true,
                };

                break;

            case actions.ERROR_DELETING_PROFILE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isDeletingProfile: false,
                    errorDeletingProfileMessage: payload.errorDeletingProfileMessage,
                };

                break;

            case actions.CLEAR_DELETED_PROFILE_INFO:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    profileDeleted: false,
                    profileDeletedMessage: null,
                    errorDeletingProfileMessage: null,
                };

                break;

            case actions.UPDATE_QUESTIONS_ORDER:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    questions: payload.questions,
                };

                break;

            case actions.SET_QUESTION_PROFILE_REFERENCE_TYPE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    referenceType: payload.type,
                };

                break;

            case actions.SET_QUESTION_PROFILE_LANGUAGE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    lang: payload.lang,
                };

                break;

            case actions.GET_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isFetchingSettings: true,
                    settingsErrorMessage: null,
                };

                break;

            case actions.RECEIVED_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isFetchingSettings: false,
                    settings: payload.settings,
                    settingsErrorMessage: null,
                };

                break;

            case actions.ERROR_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isFetchingSettings: false,
                    settingsErrorMessage: payload.settingsErrorMessage,
                };

                break;

            case actions.UPDATE_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isUpdatingSettings: true,
                    settingsUpdateMessage: null,
                    settingsUpdateErrorMessage: null,
                };

                break;

            case actions.UPDATED_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isUpdatingSettings: false,
                    settingsUpdateMessage: payload.settingsUpdateMessage,
                    settingsUpdateErrorMessage: null,
                };

                break;

            case actions.ERROR_UPDATING_QUESTION_PROFILE_SETTINGS:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isUpdatingSettings: false,
                    settingsUpdateMessage: null,
                    settingsUpdateErrorMessage: payload.settingsUpdateErrorMessage,
                };

                break;

            case actions.RESET_QUESTIONS_PROFILE_STATE:
                draft.isCreatingProfile = false;
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isCreatingQuestion: false,
                };

                break;

            case actions.CLONE_PROFILE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isCloning: true,
                    profileClonedMessage: null,
                    errorCloningProfileMessage: null,
                };
                break;

            case actions.PROFILE_CLONED:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isCloning: false,
                    profileClonedMessage: payload.profileClonedMessage,
                };
                break;

            case actions.ERROR_CLONING_PROFILE:
                draft.currentProfile = {
                    ...draft.currentProfile,
                    isCloning: false,
                    errorCloningProfileMessage: payload.errorCloningProfileMessage,
                };
                break;
        }
    });
